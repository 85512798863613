import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Hero from "../components/Hero";
// import Section from '../components/Section';
import CTA from "../components/CTA";
import BlogSection from "../components/BlogSection";
import SEO from "../components/SEO/SEO";

import { Container, Columns, Column, Section } from "../components/bulma";

export const PricingPageTemplate = ({
  image,
  title,
  heading,
  description,
  intro,
  main,
  testimonials,
  fullImage,
  pricing,
  slug,
}) => (
  <div className="content">
    <br />
    <SEO
      title={title}
      description={description}
      // image={image.childImageSharp.sizes.src}
      pathname={slug}
      article
    />
    <Hero
      title="Fixed price, fast delivery"
      subtitle="What can we build for you?"
      image="image"
      // cta
    />

    <section>
      <Container>
        <Columns centered>
          <Column size="4">
            <div className="notification">
              <h2 className="title">Simple app</h2>

              <p className="title is-1">$4,999</p>
              <p className="subtitle">10 business days</p>

              <p>
                Relatively simple mobile app without a back-end, or connecting
                to the existing API.
              </p>
              <ul className="has-text-weight-bold">
                <li>Apple and Android</li>
                <li>Standard user-interface</li>
                <li>User registration and sign in</li>
                <li>Up to 5 screens</li>
                <li>Lifetime warranty</li>
              </ul>
            </div>
          </Column>
          <Column size="4">
            <div className="notification is-dark box">
              <h2 className="title">Complex app</h2>

              <p className="title is-1">$9,999</p>
              <p className="subtitle">20 business days</p>

              <p>
                Complex app with multiple features, such as geo-location and
                maps, with a custom back-end API and a database.
              </p>
              <ul className="has-text-weight-bold">
                <li>Apple and Android</li>
                <li>Standard user-inteface</li>
                <li>User registration and sign in</li>
                <li>Up to 10 screens</li>
                <li>Custom API and database</li>
                <li>Lifetime warranty</li>
              </ul>
            </div>
          </Column>
          <Column size="4">
            <div className="notification">
              <h2 className="title">Advanced app</h2>

              <p className="title is-1">Let's talk!</p>
              <p className="subtitle">Depends on complexity</p>

              <p>
                Custom mobile app with advanced features like video processing
                or an industry-specific complex requirements.
              </p>
              <ul className="has-text-weight-bold">
                <li>Apple and Android</li>
                <li>Custom user-inteface</li>
                <li>User registration and sign in</li>
                <li>Unlimited screens</li>
                <li>Custom API and database</li>
                <li>Back-office web app</li>
                <li>Lifetime warranty</li>
              </ul>
            </div>
          </Column>
        </Columns>
      </Container>
    </section>
    <Section>
      <div className="notification">
        <p className="title has-text-centered">
          Payment plans available for startups and small businesses.
        </p>
      </div>
      <CTA />
    </Section>
  </div>
);

PricingPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  main: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  testimonials: PropTypes.array,
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pricing: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array,
  }),
};

const PricingPage = ({ data }) => {
  const { fields, frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <PricingPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        main={frontmatter.main}
        testimonials={frontmatter.testimonials}
        fullImage={frontmatter.full_image}
        pricing={frontmatter.pricing}
        slug={fields.slug}
      />
    </Layout>
  );
};

PricingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default PricingPage;

export const pricingPageQuery = graphql`
  query PricingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        heading
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
            text
          }
          heading
          description
        }
        main {
          heading
          description
          image1 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 526, quality: 92) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          image2 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 526, quality: 92) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          image3 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 1075, quality: 72) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
        testimonials {
          author
          quote
        }
        full_image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        pricing {
          heading
          description
          plans {
            description
            items
            plan
            price
          }
        }
      }
    }
  }
`;
